import { Button, Checkbox, Form, Input, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDatas } from '../../../hooks/DatasContext';
import SearchAddress from '../../Utils/SearchAddress';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';

const CreateOffer3 = ({ step, setStep, formData, setFormData, currentDeal }) => {
    const { getPagesByUserId, datas } = useDatas();
    const [form] = Form.useForm();
    const [imgList, setImgList] = useState(formData.images || []); // Initialize with formData.images if available

    const filters = datas?.filters.map(filter => ({ value: filter.id, label: filter.name }));

    useEffect(() => {
        getPagesByUserId();
    }, []);

    useEffect(() => {
        if (currentDeal) {
            const mergedData = { ...formData, ...currentDeal };
            setFormData(mergedData);
            setImgList(mergedData.images || []);
            form.setFieldsValue(mergedData);
        } else {
            form.setFieldsValue(formData);
        }
    }, [currentDeal]);

    const handleAddressChange = (fieldName, place) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: place
        }));
        form.setFieldsValue({
            [fieldName]: place
        });
    };

    const onValuesChange = (changedValues, allValues) => {
        setFormData((prevData) => ({
            ...prevData,
            ...allValues,
        }));
    };

    const onFinish = () => {
        console.log('Final Form Data:', formData);
        setStep(step + 1);
    };

    const onFileChange = ({ fileList }) => {
        setImgList(fileList);
        setFormData((prevData) => ({
            ...prevData,
            images: fileList
        }));
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLessThan3MB = file.size / 1024 / 1024 < 3;

        if (!isJpgOrPng) {
            form.setFields([
                {
                    name: 'images',
                    errors: ['Le fichier doit être au format JPG ou PNG.'],
                },
            ]);
            return Upload.LIST_IGNORE;
        }

        if (!isLessThan3MB) {
            form.setFields([
                {
                    name: 'images',
                    errors: ['La taille du fichier ne doit pas dépasser 3MB.'],
                },
            ]);
            return Upload.LIST_IGNORE;
        }

        return false;
    };

    return (
        <div className='flex flex-col w-full h-full justify-between items-center'>
            <div className='flex flex-col'>
                <h2 className='font-semibold text-2xl text-center'>{currentDeal ? "Mis-à-jour promotion" : "Créer une promotion"}</h2>
                <span className='text-lg text-center italic'>Présentation de l'offre</span>
            </div>

            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={onValuesChange}
                className='mt-10'
                style={{ width: '100%', overflowY: 'auto' }}
            >

                {/* Checkbox for isPassNeeded */}
                <Form.Item name="isPassNeeded" valuePropName="checked" initialValue={false}>
                    <Checkbox
                        checked={formData.isPassNeeded || false}
                        onChange={(e) =>
                            setFormData({ ...formData, isPassNeeded: e.target.checked })
                        }
                    >
                        Faut-il montrer le pass Behandy?
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    label="Type(s) de promo"
                    name="tags"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez choisir un type!'
                        }
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un type"
                        options={filters}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                    />
                </Form.Item>

                <Form.Item
                    label="Titre de l'offre"
                    name="offer"
                    rules={[
                        {
                            required: true,
                            message: 'Entrez votre titre!',
                        },
                        {
                            max: 140,
                            message: 'Le titre ne peut pas dépasser 140 caractères!',
                        }
                    ]}
                >
                    <Input size="large" maxLength={140} />
                </Form.Item>

                <Form.Item
                    label="Localisation"
                    name="localisation"
                >
                    <SearchAddress onAddressChange={handleAddressChange} address={formData.localisation ? formData.localisation : null} fieldName="localisation" />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    hasFeedback
                    rules={[
                        {
                            max: 1000,
                            message: 'La description ne peut pas dépasser 1000 caractères!',
                        }
                    ]}
                >
                    <TextArea size='large' maxLength={1000} showCount />
                </Form.Item>

                <Form.Item
                    label="Tag(s) de promo"
                    name="tagsDescription"
                    rules={[
                        {
                            required: true,
                            message: 'Veuillez choisir un tag!'
                        }
                    ]}
                >
                    <Select
                        mode="tags"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un tag"
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                    />
                </Form.Item>

                <Form.Item
                    label="Ajouter une image"
                    name="images"
                >
                    <Upload
                        beforeUpload={beforeUpload}
                        onChange={onFileChange}
                        multiple={false}
                        fileList={imgList}
                        listType="picture-card"
                        maxCount={1}
                    >
                        {imgList.length >= 1 ? null : <div><PlusOutlined /><div style={{ marginTop: 8 }}>Télécharger</div></div>}
                    </Upload>
                </Form.Item>


                <Form.Item className="flex justify-end mt-10">
                    {!currentDeal && <Button size="large" onClick={() => setStep(step - 1)}>Précèdent</Button>}
                    <Button size="large" type="primary" htmlType="submit" className='ml-2'>Suivant</Button>
                </Form.Item>

            </Form>
        </div>
    );
};

export default CreateOffer3;
