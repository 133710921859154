import { Button, Space, Table } from 'antd'
import React from 'react'
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../../services/requests.service';

const AdminsTable = ({page, setIsLoading, setPage}) => {

    const request = useAuthRequest();
    const CustomHeader = (props) => {
        return (
          <th {...props} className="bg-blue-500 text-white p-2 text-left">
            {props.children}
          </th>
        );
      };
  
    const columns = [
        {
          title: 'Nom',
          dataIndex: 'user',
          key: 'fullname',
          render: (text) => <span>{text.fullname}</span>,
        },
   

        {
          title: 'Email',
          dataIndex: 'user',
          key: 'email',
          render: (text) => <span>{text.email}</span>,
        },

        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">
                <Button onClick={() => deleteAdmin(record)}>Supprimer</Button>
              </Space>
            ),
          },
   
      ];

      const deleteAdmin = async (values) => {
        console.log(values)
         setIsLoading(true);


         let admins = page.admins.filter(admin => admin.user._id !== values.user._id)
         setPage({...page, admins: admins})
 
        try{
          let res = await request.patchDataAPI('/page/'+page._id+'/deleteAdmin', { userId:  values.user._id });
          let admins = page.admins.filter(admin => admin.user._id !== values.user._id)
          setPage({...page, admins: admins})          
          toast.success(res.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }catch(e){
          console.log(e)
          toast.error(e.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }
        
        setIsLoading(false); 
    
      }

  return (
    <Table columns={columns} dataSource={page.admins.filter(user => user.user.role !== 'admin')}         className="bg-white dark:bg-slate-800  shadow rounded-lg overflow-x-auto"     components={{
        header: {
          cell: CustomHeader,
        },
      }}locale={{
        emptyText: 'Aucun administrateur trouvé',
      }}/>
  )
}

export default AdminsTable