import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header/Header";
import WelcomeBanner from "../components/Dashboard/WelcomeBanner";
import { Button, Input } from "antd"; // Import Input from Ant Design
import useAuthRequest from "../services/requests.service";
import EmptyImg from '../images/icons/empty-folder.png';
import PageCard from "../components/Dashboard/PageCard";
import { useDatas } from "../hooks/DatasContext";
import CreatePageModal from "../components/Company/Page/CreatePageModal";
import success from '../assets/animations/success.json';
import { DotLoader } from "react-spinners";
import Lottie from "lottie-react";

const Dashboard = () => {
  const request = useAuthRequest()
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  const { getDatas, pages, getPagesByUserId, setPages } = useDatas();

  useEffect(() => {
    getPagesByUserId();
    getDatas();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const animationEnd = () => {
    setResponse(null);
  };

  const getPages = async (name) => {
/*     setIsLoading(true);
 */    try {
      const res = await request.getDataAPI(`/pagesByUserId?name=${name}`);
      setPages(res.pages);
    } catch (err) {
      console.error(err);
    } finally {
/*       setIsLoading(false);
 */    }
  };

  // Handle input change for the search field
  const handleSearchChange = (e) => {
    console.log("searcj")
    setSearchTerm(e.target.value);
    getPages(e.target.value); // Call getPages with the search term
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/* Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">

            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Page header */}
            <div className="flex justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Mes pages</h1>
              </div>

              {/* Right: Actions */}
              <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">

                {/* Search input */}
                <Input
                  placeholder="Rechercher une page"
                  size="large"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  allowClear
                />

                {/* Button to create a new page */}
                <Button size="large" type="primary" onClick={showModal}>
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden">Nouvelle page</span>
                </Button>

              </div>

            </div>

            {/* Conditional Rendering for Empty State */}
            {pages && pages.length === 0 ? (
              <div className="flex justify-center items-center h-full py-14 md:py-24">
                <div className="flex flex-col items-center">
                  <img src={EmptyImg} alt={"empty"} className="w-40" />
                  <span>Vous n'avez aucune page de créée!</span>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-12 gap-6">
                {pages && pages.map((page) => (
                  <PageCard key={page._id} {...page} />
                ))}
              </div>
            )}

          </div>
        </main>
      </div>

      {/* Modal for Creating New Page */}
      <CreatePageModal
        setIsLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setResponse={setResponse}
      />

      {/* Loader for Page Loading */}
      {isLoading && (
        <div className="absolute w-screen h-screen z-60 flex justify-center items-center">
          <DotLoader size={80} color="#6076E7" />
        </div>
      )}

      {/* Success Animation */}
      {response?.status === 200 && (
        <div className="absolute w-screen h-screen z-60 flex justify-center items-center">
          <Lottie className="w-56" animationData={success} onComplete={animationEnd} loop={false} />
        </div>
      )}

    </div>
  );
};

export default Dashboard;
