import React, { useEffect, useState } from 'react'
import useAuthRequest from '../../../../services/requests.service'
import OffersTable from './OffersTable';
import CreateOfferModal from '../../../Company/Offer/CreateOfferModal';

const OffersAll = ({setIsLoading}) => {
    const request = useAuthRequest();
    const [deals, setDeals] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentDeal, setCurrentDeal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [pageSize, setPageSize] = useState(10); // State for page size
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);
    const [response, setResponse] = useState(null);



    useEffect(() => {
        getDeals();
      }, [currentPage, pageSize]); // Add currentPage and pageSize as dependencies
    
      const getDeals = async () => {
        setLoading(true); // Start loading
        try {
          const res = await request.getDataAPI(`dealsAll?n=${pageSize}&page=${currentPage}`);
          setDeals(res);
        } catch (error) {
          console.error('Failed to fetch deals', error);
        } finally {
        setLoading(false); // End loading
        }
      };
    

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }
    return (
        <div className='overflow-x-auto'>            
            {deals?.deals && <OffersTable setCurrentDeal={setCurrentDeal} datas={deals} deals={deals} setDeals={setDeals} setIsModalOpen={setIsModalOpen}  currentPage={currentPage} setPage={setPage}
                pageSize={pageSize}
                total={deals?.total} // Pass total count to the table
                onPageChange={(page, size) => {
                setCurrentPage(page);
                setPageSize(size);
                }} 
            />}

            <CreateOfferModal isAdmin={true} setCurrentDeal={setCurrentDeal} currentDeal={currentDeal} deals={deals} setDeals={setDeals} page={page} setIsLoading={setIsLoading} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setResponse={setResponse}/>

        </div>
    )
}

export default OffersAll