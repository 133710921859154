import React from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Input } from "antd";

const SearchAddress = ({ onAddressChange, address, fieldName  }) => {


  

  return (
    <div>

<Autocomplete
        onLoad={(autocomplete) => {
          autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();
            place.geoFormat = {
              type: 'Point',
              coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
            };
            onAddressChange(fieldName, place);
          });
        }}
      >
        <Input
          size="large"
          value={address?address.formatted_address: null}
          placeholder="Entrez une ville"
          onChange={(e) => onAddressChange(fieldName, { formatted_address: e.target.value })}
        />
      </Autocomplete> 
        
    </div>
  );
};



export default SearchAddress;
