import { Button, Form, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDatas } from '../../../hooks/DatasContext';
import useAuthRequest from '../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';

const columns = [
    {
        title: 'Page',
        dataIndex: 'name',
        render: (text) => <a>{text}</a>,
    },
];

const CreateOffer2 = ({deals, setDeals, step, setStep, formData, setFormData, page, setIsLoading, setIsModalOpen, isAdmin }) => {
    const { getPagesByUserId, pages } = useDatas();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const request = useAuthRequest();

    const [form] = Form.useForm();
    const { postDataAPIWithoutToken } = useAuthRequest();
    form.setFieldsValue(formData);

    useEffect(() => {
        getPagesByUserId();
    }, []);

    useEffect(() => {
        // Preselect the row where the page._id matches
        if (pages.length > 0) {
            const preselectedKeys = pages
                .filter(p => p._id === page._id)
                .map(p => p._id);

            setSelectedRowKeys(preselectedKeys);
        }
    }, [pages, page]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        const selectedPages = pages.filter(p => newSelectedRowKeys.includes(p._id));
        setFormData({ ...formData, pages: selectedPages });
    };

    const getDeals = async () => {
        let res = await request.getDataAPI('/dealsByCompany/'+page._id)
        setDeals(res)
        console.log(res)
      }
    

    const submit = async () => {
        console.log(formData);
        setIsLoading(true)

        if (formData.images && formData.images.length > 0) {
            let media = await imageUpload(formData.images)
            console.log(media)
            if (Array.isArray(media) && media.length > 0 && media[0].url) {
                console.log(media);
                formData.images = media; // Assign the media array if valid
              }        }

        if (formData.filePdf && formData.filePdf.length > 0) {
            let media = await pdfUpload(formData.filePdf)
            if (Array.isArray(media) && media.length > 0 && media[0].url) {
                console.log(media);
                formData.filePdf = media; // Assign the media array if valid
              }     
            }

        console.log(formData)

        try{
            formData.pages = selectedRowKeys
            
            let res = await request.postDataAPI('/deal', { ...formData });
            setIsModalOpen(false)

            getDeals();

            toast.success("Offre postée!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
          }catch(e){
            setIsLoading(false)
            toast.error(e.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
          }

          setIsLoading(false)

   

    };


    const imageUpload = async (images) => {
        console.log("image upload");
        let imgArr = [];

        for (const item of images) {
            const formDataS3 = new FormData();
            formDataS3.append("image", item.originFileObj);

            console.log(JSON.stringify(formDataS3))
            try {
                const resS3 = await postDataAPIWithoutToken('uploadImage', formDataS3);
                console.log(resS3)
                imgArr.push({ url: resS3.uploadLocation });
            } catch (error) {
                console.error("Image upload failed:", error);
            }
        }



        return imgArr;
    };

    const pdfUpload = async (pdfFiles) => {
        console.log("PDF upload");
        console.log(pdfFiles)
        let pdfArr = [];

        for(const item of pdfFiles){
            const formDataS3 = new FormData();    
            // Directly append the PDF file to formData
            formDataS3.append("file", item, item.name);
            
            // Here, adjust the parameters as necessary for your backend requirements
            formDataS3.append("upload_preset", "bb5qshs3"); // Adjust or remove if not needed
            formDataS3.append("cloud_name", "behandy"); // Adjust or remove if not needed
    
            console.log(formDataS3)
            // Replace 'uploadFile' with your actual backend endpoint for file uploads
            const resS3 = await postDataAPIWithoutToken('uploadFile', formDataS3);
            console.log(resS3)
            // Ensure the response handling matches your backend's response structure
            pdfArr.push({url: resS3.uploadLocation});
        }

        return pdfArr;
    }
    
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    return (
        <div className='flex flex-col w-full h-full justify-between items-center'>
            <div className='flex flex-col'>
                <h2 className='font-semibold text-2xl text-center'>Créer une promotion</h2>
                <span className='text-lg text-center italic'>Pour quelle(s) page(s)</span>
            </div>

            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={pages}
                className='overflow-y-auto w-full my-5'
                size="middle"
                pagination={{
                    pageSize: 6,
                }}
                rowKey="_id" // Ensure that the table uses _id as the unique key
            />
            <div className='flex gap-2 self-end'>
                <Button
                    onClick={() => setStep(step-1)}
                    className='flex self-end'
                    size="large"
                >
                    Précèdent
                </Button>

                <Button
                    onClick={submit}
                    className='flex self-end'
                    size="large"
                    type="primary"
                    disabled={selectedRowKeys.length === 0} // Disable if no page is selected
                >
                    Poster
                </Button>
            </div>
        </div>
    );
};

export default CreateOffer2;
