import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useAuthRequest from '../../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';

const CreateFilterCategorieModal = ({ currentCate, isModalOpen, setIsModalOpen, setIsLoading, setCategories, categories, handleClose }) => {
    const [step, setStep] = useState(1);
    const request = useAuthRequest();
    const [form] = Form.useForm();

    const [formData, setFormData] = useState({
        title: '',
        filters: [],
        rank: ''
    });

    useEffect(() => {
        // Update formData based on currentCate whenever it changes
        if (currentCate) {
            setFormData({
                title: currentCate.title || '',
                filters: currentCate.filters || [],
                rank: currentCate.rank || ''
            });
        } else {
            setFormData({
                title: '',
                filters: [],
                rank: ''
            });
        }
    }, [currentCate]);

    useEffect(() => {
        // Set form fields whenever formData changes
        form.setFieldsValue(formData);
    }, [formData, form]);

    const handleCancel = () => {
        setIsModalOpen(false);
        handleClose(); // Ensure handleClose is called
    };

    const afterClose = () => {
        setStep(1);
        form.resetFields(); // Reset form fields after the modal is closed
        setFormData({ title: '', filters: [], rank: '' }); // Reset form data
    };

    const onValuesChange = (changedValues, allValues) => {
        setFormData((prevData) => ({
            ...prevData,
            ...allValues,
        }));
    };

    const onFinish = async () => {
        setIsLoading(true);
        if (!currentCate) {
            try {
                let res = await request.postDataAPI('filterCategory', { ...formData });
                setCategories([...categories, res.newCategorie]);
                setIsLoading(false);

                toast.success(res.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setIsModalOpen(false);
            } catch (error) {
                setIsLoading(false);
                toast.error(error.msg ? error.msg : "Une erreur est survenue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setIsModalOpen(false);
            }
        } else {
            try {
                let res = await request.patchDataAPI(`filterCategory/${currentCate._id}`, { ...formData });
                const updatedCategories = categories.map((cat) =>
                    cat._id === currentCate._id ? res.updatedCategorie : cat
                );
                setCategories(updatedCategories);
                setIsLoading(false);

                toast.success(res.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setIsModalOpen(false);
            } catch (error) {
                setIsLoading(false);
                toast.error(error.msg ? error.msg : "Une erreur est survenue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setIsModalOpen(false);
            }
        }
    };

    return (
        <Modal
            centered
            bodyStyle={{ height: "65vh", maxHeight: "65vh", overflowY: "hidden" }}
            footer={null}
            open={isModalOpen}
            onCancel={handleCancel}
            afterClose={afterClose}
        >
            <div className='flex flex-col w-full h-full justify-between items-center'>
                <div className='flex flex-col'>
                    {!currentCate && <h2 className='font-semibold text-2xl text-center'>Créer une nouvelle catégorie</h2>}
                    {currentCate && <h2 className='font-semibold text-2xl text-center'>Mise-à-jour </h2>}
                </div>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    onValuesChange={onValuesChange}
                    className='mt-10'
                    style={{ width: '100%', overflowY: 'auto' }}
                >
                    <Form.Item
                        label="Titre de la catégorie"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Entrez votre titre!',
                            },
                            {
                                max: 140,
                                message: 'Le titre ne peut pas dépasser 140 caractères!',
                            }
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>

                    <Form.Item
                        label="Tag(s)"
                        name="filters"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez choisir un tag!'
                            }
                        ]}
                    >
                        <Select
                            mode="tags"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Choississez au moins un tag"
                            size="large"
                            dropdownStyle={{ zIndex: "100000" }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Position"
                        name="rank"
                    >
                        <Input size="large" type="number" />
                    </Form.Item>

                    <Form.Item className="flex justify-end mt-10">
                        {!currentCate && <Button size="large" type="primary" htmlType="submit" className='ml-2'>Créer</Button>}
                        {currentCate && <Button size="large" type="primary" htmlType="submit" className='ml-2'>Mettre-à-jour</Button>}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateFilterCategorieModal;
