import { Button, Form, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';
import TextArea from 'antd/es/input/TextArea';


const CreationNotificationModal = ({ isModalOpen, setIsModalOpen, setIsLoading }) => {
  const request = useAuthRequest();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});


  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {
    form.resetFields();
    setFormData({});
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...allValues,
    }));
  };


  const onFinish = async () => {
    console.log('Final Form Data:', formData);

    setIsLoading(true);
    try {
      let res = await request.postDataAPI('send-notificationAll', { ...formData });
      setIsLoading(false);

      toast.success(res.msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);

      toast.error(error.msg ? error.msg : 'Une erreur est survenue', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsModalOpen(false);
    }
  };

  return (
    <Modal
      centered
      bodyStyle={{ height: '65vh', maxHeight: '65vh', overflowY: 'hidden' }}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
    >
      <div className="flex flex-col w-full h-full items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-2xl text-center">Envoyer Notification</h2>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onValuesChange}
          className="mt-10  h-full flex flex-col justify-between"
          style={{ width: '100%', overflowY: 'auto' }}
        >


          <Form.Item
            label="Message"
            name="message"
          >
            <TextArea  size="large" />
          </Form.Item>

          <Form.Item className="flex justify-end mt-10 ">
            <Button size="large" type="primary" htmlType="submit" className="ml-2">
              Envoyer
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreationNotificationModal;
