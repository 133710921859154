import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthProvider';

function AdminSidebar(props) {
  const location = useLocation();
  const auth = useAuth();

  const { pathname, search } = location;

  const isActive = (page) => search === `?page=${page}`;

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/administrator?page=offers`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('offers') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('offers') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7z"/></svg>
              <span className={`text-sm font-medium ${isActive('offers') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Gestion offres</span>
            </NavLink>
          </li>

          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/administrator?page=feed`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('feed') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
            >
    

              <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('feed') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7z"/></svg>
              <span className={`text-sm font-medium ${isActive('feed') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Gestion Feed</span>
            </NavLink>
          </li>

          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/administrator?page=notification`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('notification') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
            >
    

              <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('notification') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7z"/></svg>
              <span className={`text-sm font-medium ${isActive('notification') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Gestion Notification</span>
            </NavLink>
          </li>

        </ul>
      </div>
    </div>
  );
}

export default AdminSidebar;
