import React, { useEffect, useState } from 'react'
import useAuthRequest from '../../../../services/requests.service';
import ReportDealsTable from './ReportDealsTable';
import ReportDealModal from './ReportDealModal';

const ReportDeals = ({ setIsLoading }) => {
    const request = useAuthRequest();

    const [loading, setLoading] = useState(true);
    const [deals, setDeals] = useState(null);
    const [currentDeal, setCurrentDeal]= useState(null);
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [pageSize, setPageSize] = useState(10); // State for page size
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getReportDeals();
    }, [currentPage, pageSize]); // Add currentPage and pageSize as dependencies

    const getReportDeals = async () => {
        setLoading(true); // Start loading
        try {
            const res = await request.getDataAPI(`reportDeals?n=${pageSize}&page=${currentPage}`);
            setDeals(res);
        } catch (error) {
            console.error('Failed to fetch deals', error);
        } finally {
            setLoading(false); // End loading
        }
    };


    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }
    return (
        <div className='overflow-x-auto'>
            {deals?.reportDeals && <ReportDealsTable datas={deals} deals={deals} setDeals={setDeals} currentPage={currentPage} setCurrentDeal={setCurrentDeal} setIsModalOpen={setIsModalOpen}
                pageSize={pageSize}
                total={deals?.total} // Pass total count to the table
                onPageChange={(page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                }
                }
            />}

            <ReportDealModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading} currentDeal={currentDeal} setCurrentDeal={setCurrentDeal} />

        </div>
    )
}

export default ReportDeals