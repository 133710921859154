import React, { useEffect, useState } from 'react'
import useAuthRequest from '../../../services/requests.service';
import NotifsTable from './NotifsTable';
import { Button } from 'antd';
import CreationNotificationModal from './CreateNotificationModal';

const NotificationManagement = ({ setIsLoading }) => {
    const request = useAuthRequest();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [notifs, setNotifs] = useState(null)
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [pageSize, setPageSize] = useState(10); // State for page size
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        getNotifs();
    }, [currentPage, pageSize]); // Add currentPage and pageSize as dependencies

    const getNotifs = async () => {
        setLoading(true); // Start loading
        try {
            const res = await request.getDataAPI(`notifications?n=${pageSize}&page=${currentPage}`);
            setNotifs(res);
        } catch (error) {
            console.error('Failed to fetch deals', error);
        } finally {
            setLoading(false); // End loading
        }
    };


    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }
    return (
        <div className='h-full m-4 md:w-full '>
            <div className="sm:flex sm:justify-end sm:items-center mb-4">


                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                    <Button size="large" type="primary" onClick={() => setIsModalOpen(true)}>
                        <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="hidden xs:block ml-2">Envoyer une notification</span>
                    </Button>
                </div>

            </div>
            {notifs?.notifs && <NotifsTable currentPage={currentPage} datas={notifs} notifs={notifs} setNotifs={setNotifs}
                pageSize={pageSize}
                total={notifs?.count} // Pass total count to the table
                onPageChange={(page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                }}
            />}

<CreationNotificationModal   setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setIsLoading={setIsLoading} notifs={notifs} setNotifs={setNotifs} />

        </div>
    )
}

export default NotificationManagement