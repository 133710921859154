import { Button, Space, Table, Tag, Modal } from 'antd';
import React from 'react';
import moment from 'moment';

const { confirm } = Modal;

const NotifsTable = ({ datas, setNotifs, currentPage, pageSize, total, onPageChange }) => {
  const CustomHeader = (props) => {
    return (
      <th {...props} className="bg-blue-500 text-white p-2 text-left">
        {props.children}
      </th>
    );
  };



  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: '60%',

      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Destinataire',
      dataIndex: 'recipientsCount',
      key: 'recipientsCount',
      render: (text) => <span>{text}</span>,
    },


    {
      title: 'Date envoi',
      dataIndex: 'timestamp',
      key: 'timestamp',
      className: 'hide-on-mobile', // Apply the responsive class here
      render: (createdAt) => (
        <span>{moment(createdAt).format('DD/MM/YYYY à HH:mm')}</span>
      ),
    },

  ];

  return (
    <Table
      columns={columns}
      dataSource={datas.notifs}
      className="bg-white dark:bg-slate-800 shadow rounded-lg"
      components={{
        header: {
          cell: CustomHeader,
        },
      }}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: total,
        onChange: onPageChange,
      }}
    />
  );
};

export default NotifsTable;
