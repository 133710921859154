import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Space, Table } from 'antd';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';
import { DeleteOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import CreateFeaturedCompanyModal from './CreateFeaturedCompanyModal';

const { confirm } = Modal;

const CustomHeader = (props) => {
  return (
    <th {...props} className="bg-blue-500 text-white p-2 text-left">
      {props.children}
    </th>
  );
};

const FeaturedCompany = ({ setIsLoading }) => {
  const request = useAuthRequest();
  const [featuredPages, setFeaturedPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingRow, setEditingRow] = useState(null); // Track which row is being edited
  const [editedPosition, setEditedPosition] = useState({}); // Store the edited position
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getFeaturedPages();
  }, []);

  const getFeaturedPages = async () => {
    setLoading(true);
    try {
      const res = await request.getDataAPI(`/featuredPages`);
      setFeaturedPages(res.featured);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (record) => {
    confirm({
      title: 'Êtes-vous sûr de vouloir supprimer cette page?',
      content: 'Cette action est irréversible',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      centered: true,
      async onOk() {
        setIsLoading(true);
        try {
          let res = await request.deleteDataAPI(`/featuredPage/${record._id}`);
          setIsLoading(false);

          const updatedFeaturedPages = featuredPages.filter((page) => page._id !== record._id);
          setFeaturedPages([...updatedFeaturedPages]);

          toast.success(res.msg, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
        } catch (e) {
          setIsLoading(false);
          toast.error(e.msg ? e.msg : 'Une erreur est survenue', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
        }
      },
    });
  };

  const handleClose = ()=>{
    setIsModalOpen(true)
  }
  const handleEdit = (record) => {
    setEditingRow(record._id); // Set the row to edit mode
    setEditedPosition({ [record._id]: record.rank }); // Pre-fill the current rank value
  };

  const handlePositionChange = (value, record) => {
    setEditedPosition({ ...editedPosition, [record._id]: value }); // Update the edited position for the specific row
  };

  const handleSave = async (record) => {
    const newPosition = editedPosition[record._id];
    setIsLoading(true);

    try {
      const res = await request.patchDataAPI(`/featuredPage/${record._id}`, { rank: newPosition });

      // Update the featuredPages list with the new position
      const updatedFeaturedPages = featuredPages.map((page) =>
        page._id === record._id ? { ...page, rank: newPosition } : page
      );
      setFeaturedPages(updatedFeaturedPages);
      setIsLoading(false);

      toast.success(res.msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });

      setEditingRow(null); // Exit edit mode
    } catch (error) {
      setIsLoading(false);
      toast.error(error.msg ? error.msg : 'Une erreur est survenue', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    }
  };

  const columns = [
    {
      title: 'Entreprise',
      dataIndex: 'page',
      key: 'page',
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: 'Position',
      dataIndex: 'rank',
      key: 'rank',
      render: (text, record) =>
        editingRow === record._id ? (
          <Input
            size="small"
            value={editedPosition[record._id] || ''}
            onChange={(e) => handlePositionChange(e.target.value, record)}
            style={{ width: 60 }}
          />
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {editingRow === record._id ? (
            <Button type="primary" className='w-10 h-10 ' onClick={() => handleSave(record)}>
              <CheckOutlined  /> {/* Save Button */}
            </Button>
          ) : (
            <Button className='w-10 h-10 ' onClick={() => handleEdit(record)}>
              <EditOutlined  /> {/* Edit Button */}
            </Button>
          )}
          <Button danger onClick={() => handleDelete(record)} className='w-10 h-10'><DeleteOutlined /></Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="sm:flex sm:justify-end sm:items-center mb-4">
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <Button size="large" type="primary" onClick={() => { setIsModalOpen(true) }}>
            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Ajouter entreprise</span>
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={featuredPages}
        className="bg-white dark:bg-slate-800 shadow rounded-lg  overflow-x-auto"
        components={{
          header: {
            cell: CustomHeader,
          },
        }}
      />

      <CreateFeaturedCompanyModal handleClose={handleClose} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading} setFeaturedPages={setFeaturedPages} featuredPages={featuredPages}/>
    </div>
  );
};

export default FeaturedCompany;
