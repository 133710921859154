import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

import PrivateRoute from "./router/PrivateRoute";
import PublicRoute from "./router/PublicRoute";
import ConditionalRoute from "./router/ConditionalRoute";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { ConfigProvider } from "antd";
import Signup from "./pages/Signup";
import { AuthProvider, useAuth } from "./hooks/AuthProvider";
import ThemeProvider from "./utils/ThemeContext";
import Company from "./pages/Company";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatasProvider, useDatas } from "./hooks/DatasContext";
import { LoadScript } from "@react-google-maps/api";
import SettingsPage from "./pages/Settings";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SettingsPrivacyPolicy from "./pages/PrivacyPolicy";
import Confirm from "./pages/Confirm";
import DashboardAdmin from "./pages/DashboardAdmin";

function App() {
  const REACT_APP_API_PLACE = process.env.REACT_APP_API_PLACE;
  const [libraries] = useState(['places']);

  

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#6076E7' } }}>
            <ToastContainer />

      <div className="App">

        <ThemeProvider>

        <Router>

         <LoadScript
        googleMapsApiKey={REACT_APP_API_PLACE}
        libraries={libraries}
      > 
          <AuthProvider>
          <DatasProvider>

            <Routes>
              <Route path="/" element={<ConditionalRoute />} />
              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/privacyPolicy" element={<SettingsPrivacyPolicy />} />
                <Route path="/confirm" element={<Confirm />} />

              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/settings" element={<SettingsPage/>} />
                <Route path="/administrator" element={<DashboardAdmin />} />

                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/company/:id" element={<Company />} />
{/*                 <Route path="/company/:id/offers" element={<CompanyOffers />} />
 */}
              </Route>
              {/* Other routes */}
            </Routes>
            </DatasProvider>
          </AuthProvider>
           </LoadScript>
         </Router>
        </ThemeProvider> 

      </div>
    </ConfigProvider>
  );
}

export default App;
