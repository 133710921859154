import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import useAuthRequest from '../services/requests.service';
import { useAuth } from './AuthProvider';

const DatasContext = createContext();

export function useDatas() {
  return useContext(DatasContext);
}

export const DatasProvider = ({ children }) => {
  const request = useAuthRequest();
  const {user} = useAuth();

  const [datas, setDatas] = useState(null);

  const [pages, setPages] = useState([]);
  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      getDatas();
    }
  }, []);

  useEffect(() => {
    if(user){
      getPagesByUserId();

    }
  }, [user])
  

  const getDatas = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/datas/fr`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();

    if(response.ok){
      setDatas(data);
    }

  };

  const getPagesByUserId = async () => {
    let res = await request.getDataAPI('/pagesByUserId?sortBy=name')
    setPages(res?.pages)
  }


  const value = {
    datas,
    setDatas,
    getDatas,
    pages,
    setPages,
    getPagesByUserId
  };

  return <DatasContext.Provider value={value}>{children}</DatasContext.Provider>;
};
